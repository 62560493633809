@import "~@/assets/scss/global.scss";

























































































.field-container {
  margin-bottom: 10px;
}

.complex-container {
  .field-container {
    display: inline-block;
    margin: 0 10px 10px 0;
    width: 370px;

    >div {
      display: inline-block;
    }
  }
}

.complex-title {
  width: 100%;
  background: #e3f2fd;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
}

.tip-rule {
  font-size: 12px;
  color: #999;
}
