@import "~@/assets/scss/global.scss";






































































































































































.wrapper {
  width: 60%;
  margin:20px auto;
  box-sizing: border-box;

  > div {
    margin-bottom: 10px;
  }
}
